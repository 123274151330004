<template>
    <v-main fluid>
      <transition name="page" mode="out-in">
            <router-view />
      </transition>
    </v-main>
</template>
<script>
export default {
    
}
</script>
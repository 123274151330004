<template>
  <v-app-bar
    app
    flat
    dark
    class="justify-center aligth-center"
  >
    <v-avatar class="mr-1">
      <v-img
        src="@/assets/TallerSeguro.svg"
      ></v-img>

    </v-avatar>
    <v-toolbar-title>Taller Seguro</v-toolbar-title>
    <v-spacer />
      <!--<div class="hidden-sm-and-down">
        <v-card-text class="sucursal_name">{{ user }}</v-card-text>
    </div>-->
    <v-btn icon><v-icon>mdi-home</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-magnify</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-account-circle </v-icon></v-btn>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      user: "Jesus Medina Calderon",
    };
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 150px;
  padding: 5px;
}
.sucursal_name {
  font-size: 20px;
}
.titulo {
  font-family: Georgia;
  font-size: 30px;
}
</style>
<template>
    <v-app>
        <app-header />
        <app-view />
    </v-app>
</template>
<script>
import AppHeader from "@/layout/AppHeader";
import AppView from "@/layout/AppView";

export default {
    components: {
    "app-header": AppHeader,
    "app-view": AppView
  },
}
</script>